<template>
  <div class="px-2 xl:py-3 md:px-0 md:py-0" v-if="item">
    <div
      class="flex items-center justify-between h-9 md:h-16 pl-4 pr-2 md:pr-6"
      :class="background(item)"
    >
      <div class="flex flex-col justify-center items-start w-1/5">
        <div
          class="font-nexa font-bold text-white text-lg md:text-2xl leading-5"
        >
          {{ item.name }}
        </div>
        <p class="text-agrogo-green-6 text-3xs md:text-xs space-x-px">
          <span
            v-for="(start, i) in starts"
            :key="i"
            class="icon-star2"
            :class="{
              'text-agrogo-green-6': start.isActive,
              'text-agrogo-green-5': !start.isActive,
            }"
          />
        </p>
      </div>

      <div
        class="flex justify-start items-center font-dinpro font-medium text-white text-2xl"
      >
        <span
          class="icon-signo-pesos text-white text-xl leading-none pr-1.5"
        ></span>
        {{ item.price }}
      </div>
      <div class="flex items-center justify-end">
        <button
          @click.prevent="$emit('selected', item)"
          type="button"
          class="btn-primary flex items-center justify-center h-6 btn-width-step8 md:h-9 bg-agrogo-yellow-1 rounded-xl text-xs md:text-base text-agrogo-green-2 font-nexa font-bold"
          :disabled="(isBusy && item.id == idItem) || isSaveImage || isError"
        >
          <div class="loader" v-if="isBusy && item.id == idItem" />
          <span v-else>Seleccionar</span>
        </button>
      </div>
    </div>
    <div
      class="w-full border border-t-0 border-agrogo-yellow-1 border-opacity-25"
    >
      <div class="flex px-4 pt-4 h-auto w-auto justify-start">
        <div
          class="style-info2 text-white text-sm md:text-base font-dinpro"
          v-html="item.description_large"
        ></div>
      </div>
      <div class="flex flex-col px-4 pt-3 pb-5 h-auto w-auto space-y-3">
        <div class="flex pl-4 md:pl-6 justify-between">
          <div class="flex items-center col-span-2 pr-2 md:pr-4">
            <a
              class="font-dinpro text-white text-xs text-left opacity-80 md:text-sm"
              v-html="item.description_short"
            />
          </div>
          <div v-if="item.url_video">
            <div class="tooltip-container z-10">
              <button
                type="button"
                class="btn-preview fas fa-question-circle tooltip-trigger"
                @click.prevent="openModal(item.url_video)"
              >
                Previsualizar
                <span class="icon-desplegar transform ml-1"></span>
              </button>
              <!-- <div class="tooltip-btn-preview">
                <span class="font-dinpro font-normal text-white text-base mt-4"
                  >Previsualizar</span
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["openModal"],
  props: {
    item: {
      type: Object,
      default: null,
    },
    idItem: {
      type: Number,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    isSaveImage: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      starts: [
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
      ],
    };
  },
  watch: {
    item() {
      const me = this;
      me.fillStart(me.item);
    },
  },
  methods: {
    fillStart(item) {
      const me = this;
      if (item.id == 2) {
        // Super
        me.starts.forEach((el) => {
          el.isActive = true;
        });
      } else {
        // Plus
        me.starts.forEach((el, index) => {
          if (index <= 3) {
            el.isActive = true;
          }
        });
      }
      // Super 5 estrellas1
      // Plus 4 estrellas2
      // Alto 3 3
      // Medio 2 4
      // Basico 1 5
    },
    background(item) {
      let color = null;
      if (item.id == 2) {
        // Super
        color = "backgroun1";
      } else {
        // Plus
        color = "backgroun2";
      }

      return color;
    },
    openModal(url) {
      const me = this;
      me.$emit("openModal", url);
    },
  },
  mounted() {
    const me = this;
    me.fillStart(me.item);
  },
};
</script>

<style lang="css">
.backgroun1 {
  background-color: #4c9800;
}
.backgroun2 {
  background-color: #7abb39;
}
</style>
