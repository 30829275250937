<template>
  <section class="bg-agrogo-green-2">
    <div
      class="grid grid-cols-1 lg:grid-cols-1 md:grid-cols-1 bg-agrogo-yellow-2 max-h-88 md:max-h-103"
    >
      <div class="flex flex-col items-center md:flex-row md:pl-5 py-2 md:py-0">
        <span
          class="font-nexa font-bold text-base text-agrogo-green-2 md:text-2xl pt-6 pb-4"
          >Datos para facturación electrónica</span
        >
      </div>
    </div>
    <section
      v-if="isBusy"
      class="containerGifLoading w-80 md:w-full max-h-88 md:max-h-245"
    >
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <section v-else>
      <div
        class="bg-agrogo-modal h-full pt-4 md:pt-8 px-3 pb-5 md:px-8 md:bg-transparent"
      >
        <div class="grid grid-cols-1">
          <div class="grid grid-cols-1 gap-x-2 md:gap-x-0 w-full">
            <div
              class="grid grid-cols-1 lg:grid-cols-2 mb-8 md:items-center md:bg-agrogo-green-5 md:mr-1 md:px-4 md:mb-2 md:py-4"
            >
              <div class="hidden lg:flex pl-5">
                <a class="font-nexa text-white text-sm text-left md:text-lg"
                  >Departamento</a
                >
              </div>
              <div class="flex justify-end items-center">
                <v-select
                  :options="departments"
                  v-model="formData.department"
                  class="style-chooser2 w-full"
                  label="name"
                  @update:modelValue="loadMunicipies()"
                  placeholder="Departamento"
                >
                  <template #no-options>Sin opciones</template>
                </v-select>
              </div>
            </div>
            <div
              class="grid grid-cols-1 lg:grid-cols-2 mb-8 md:items-center md:bg-agrogo-green-5 md:ml-1 md:px-4 md:mb-2 md:py-4"
            >
              <div class="hidden lg:flex pl-5">
                <a class="font-nexa text-white text-sm text-left md:text-lg"
                  >Municipio</a
                >
              </div>
              <div class="flex justify-end items-center">
                <v-select
                  :options="municipalities"
                  id="municipality"
                  v-model="formData.municipality"
                  class="style-chooser2 w-full"
                  label="name"
                  placeholder="Municipio"
                >
                  <template #no-options>Sin opciones</template>
                </v-select>
              </div>
            </div>
            <div
              class="grid grid-cols-1 lg:grid-cols-2 mb-8 md:items-center md:bg-agrogo-green-5 md:ml-1 md:px-4 md:mb-2 md:py-4"
            >
              <div class="hidden lg:flex pl-5">
                <a class="font-nexa text-white text-sm text-left md:text-lg"
                  >Dirección</a
                >
              </div>
              <div class="flex justify-end items-center">
                <input
                  type="text"
                  class="input-standard-transparent md:max-w-236"
                  v-model="$v.formData.address.$model"
                  @blur="$v.formData.address.$touch()"
                  placeholder="Dirección para facturación"
                />
              </div>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-if="$v.formData.address.$error"
              >
                El campo debe tener mínimo 4 caracteres.
              </p>
              <p
                class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                v-else
              >
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- CONTENEDOR BOTONES DE NAVEGACION -->
      <div
        class="flex items-center justify-end bg-agrogo-black-2 pb-14 md:bg-agrogo-green-2 md:pb-8 pr-8"
      >
        <button
          @click="saveDataLead()"
          :disabled="isBusy || $v.$invalid"
          type="button"
          class="btn-nav-leads"
        >
          Siguiente
          <span
            class="icon-desplegar flex items-center transform -rotate-90 text-xs ml-1 leading-none md:ml-2 md:text-sm"
          ></span>
        </button>
      </div>
    </section>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import { minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import validationMixin from "@/mixins/validationMixin";

export default {
  inject: ["provider"],
  mixins: [validationMixin],
  emits: ["next"],
  data() {
    return {
      $v: useVuelidate(),
      repository: this.provider.publishFarmRepository,
      departments: [],
      municipalities: [],
      isBusy: false,
      formData: {
        address: null,
        department: null,
        municipality: null,
      },
    };
  },
  validations() {
    return {
      formData: {
        address: {
          required,
          minLength: minLength(4),
        },
        department: {
          required,
        },
        municipality: {
          required,
        },
      },
    };
  },
  methods: {
    async loadDepartments() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllDepartsSiigo(dataAditional);
        me.departments = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    async loadMunicipies() {
      const me = this;
      me.resetSelect();
      me.municipalities = me.formData.department.municipalities;
    },
    resetSelect() {
      const me = this;
      me.formData.municipality = null;
      me.municipalities = [];
    },
    saveDataLead() {
      const me = this;
      me.$emit("next", me.formData);
    },
  },
  async mounted() {
    const me = this;
    await me.loadDepartments();
  },
};
</script>
